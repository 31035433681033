import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import SupportPage from "../../components/layout/SupportPage"
import PageHeader from "../../components/layout/PageHeader"
import GoogleImage from "../../components/GoogleImage"

const List = styled.ul`
  list-style-type: none;
  margin: 0px;
  padding: 0px;

  li {
    background-color:white;
    padding: 5px;
    margin-bottom: 5px;

    a {
      display: flex;
      align-items: center;
    }

    img {
      margin-right: 12px;
      height: 75px;
      width: 60px;
    }
  }
`

const InstallationPage = () => (
  <SupportPage page="installation" title="Easy Sign Installation Instructions">
      <PageHeader title="Installation Instructions" />

      <List>
        <li>
            <Link to="/documents/installation-ezlit-letters-and-logos/">
              <GoogleImage src="Documents/install-guide.jpg" alt="EZLit Sign Installation Instructions" width={60} height={75} />
              EZLit Sign Installation Instructions
            </Link>
        </li>
        <li>
          <Link to="/documents/installation-raceway-letters-and-logos/">
            <GoogleImage src="Documents/raceway-lit-letters-logos-installation.jpg" alt="Raceway Sign Installation Instructions" width={60} height={75} />     
            Raceway Sign Installation Instructions
          </Link>
        </li>
        <li>
          <Link to="/documents/installation-self-contained/">
              <GoogleImage src="Documents/self-contained-install-guide.jpg" alt="Self Contained Sign Installation Instructions" width={60} height={75} />       
              Self Contained Sign Installation Instructions
          </Link>
        </li>
        <li>
          <Link to="/documents/installation-halo-letters-and-logos/">
              <GoogleImage src="Documents/install-guide.jpg" alt="Halo Lit Sign Installation Instructions" width={60} height={75} />       
              Halo Lit Sign Installation Instructions
          </Link>
        </li>
        <li>
          <Link to="/documents/installation-double-sided-tape/">
              <GoogleImage src="Documents/install-guide.jpg" alt="Acrylic & PVC Tape Method Installation Instructions" width={60} height={75} />       
              Acrylic & PVC Tape Method Installation Instructions
          </Link>
        </li>          
        <li>
          <Link to="/documents/installation-stud-mount/">
              <GoogleImage src="Documents/install-guide.jpg" alt="Stud Mount Installation Instructions" width={60} height={75} />       
              Stud Mount Installation Instructions
          </Link>
        </li>        
      </List>
  </SupportPage>
)

export default InstallationPage
